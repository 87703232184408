@import "../../styles/colors";
@import "../../styles/media";

.popup-overlay {
  background: rgba(255, 255, 255, 0.7);
}
.popup-content {
  width: 100%;

  @media (min-width: $sm) {
   width: 70%;
  }
}

.popup-overlay {
  overflow-y: auto;
}
.popup-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  > button {
    margin-right: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  @media (min-width: $sm) {
    margin: 0 auto;
    // max-width: 1340px;
    // width: 70%;
  }
}
.popup-wrapper__full {
  @media (min-width: $sm) {
    width: 98%;
  }
}
.popup-wrapper__admin {
  @media (min-width: $sm) {
    width: 75%!important;
  }
}
 

