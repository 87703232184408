@import "../../../styles/colors";
@import "../../../styles/media";

.errorsList {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
align-items: flex-end;
  & > div {
    margin-bottom: 25px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
