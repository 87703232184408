@import '../../../styles/colors';
@import '../../../styles/media';

.wrapper {
  display: flex;
  flex: 1 0 100%;
  overflow-y: auto;
  max-width: 100%;
  width: 100%;
}

.table {
  display: table;
  //flex-direction: column;
}

.item {
  align-items: center;
  min-height: 50px;
  padding: 5px;
}

.head {
  background-color: $foam-color;
  text-align: left;
  &.grey {
    background-color: $light-grey-background;
  }
}

.even {
  background-color: $black-squeeze-color;
}

.first {
  padding-left: 45px;
}

.last {
  padding-right: 45px;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

.color {
  margin-top: 15px;
}

.live {
  background-color: rgba(#B0F2C8, 0.75);
}

.suspended {
  background-color: rgba($color-suspended, 0.4);
}

.rejected {
  background-color: #F8EAED;
}

.finished {
  background-color: rgba($color-finished, 0.4);
}

.wa {
  background-color: rgba(#F2F59A, 0.75);
}

.icon {
  height: 15px;
  margin-right: 4px;
}
