@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swiper {
  padding-bottom: 35px !important;
  max-width: 1092px;
  width: 100%;

}

video {
  object-fit: fill;
  object-position: center;

}

.swiper-slide {
  max-width: 1092px;
  width: 100%;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.shadow-custom {
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
}

.aspect-ratio-img {
  aspect-ratio: 279/167;
}

a {
  text-decoration: none;
  outline: none;
}

a:focus {
  outline: none !important;
}

.container {
  padding: 0px 0 84px !important;
  max-width: none !important;

  @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}

.placeholder-color::placeholder {
  color: #7a7a7a !important;
}

.select-font>div>div>.css-zynqph-singleValue {
  font-family: Montserrat, sans-serif !important;
}

.font-semibold {
  font-family: MontserratSemiBold, "sans-serif";
}

.font-light {
  font-family: MontserratLight, "sans-serif";
}

.font-medium {
  font-family: MontserratMedium, "sans-serif";
}

.font-bold {
  font-family: MontserratBold, "sans-serif";
}

.font-normal {
  font-family: Montserrat, "sans-serif" !important;
}

textarea {
  resize: none !important;
  resize: vertical !important;
}

.card-shadow:hover {
  box-shadow: 20px 30px 60px 0px rgb(0 0 0 / 16%);
}

div {
  word-break: break-word;
}

/* Google Maps */
/* 
 * Always set the map height explicitly to define the size of the div element
 * that contains the map. 
 */
#map {
  height: 100%;
}

/* 
 * Optional: Makes the sample page fill the window. 
 */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#description {
  font-family: montserrat;
  font-size: 14px;
  font-weight: 400;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

.pac-card {
  background-color: #fff;
  border: 0;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  font-family: Roboto;
  padding: 0;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  word-break: keep-all;
}

.ReactTags__tag {
  cursor: move;
  border-radius: 20px;
  padding: 4px 12px;
  background-color: #efefef;
  color: #404040;
  font-size: 14px;
  margin-left: 5px;
  margin-bottom: 5px;
  height: min-content;
}

.ReactTags__remove {
  width: 16px !important;
}

.ReactTags__tagInputField {
  border-radius: 0px !important;
  border: none !important;
  outline: none !important;
  line-height: normal;
  width: fit-content !important;
}

.p__details {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #000000 !important;
  line-height: 22px !important;
  margin-top: 4px !important;
  letter-spacing: calc(1em * 0.001 * 30) !important;
  font-family: Montserrat, sans-serif !important;
}

.h__header {
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #000000 !important;
  line-height: 29px !important;
  letter-spacing: calc(1em * 0.001 * 60) !important;
  font-family: MontserratSemiBold, sans-serif !important;
}

.sub__header {
  font-size: 16px !important;
  color: #000000 !important;
  font-family: MontserratSemiBold, "sans-serif" !important;
  line-height: 20px !important;
  letter-spacing: calc(1em * 0.001 * 20) !important;
}

.sub__header_2 {
  font-size: 20px !important;
  color: #000000 !important;
  font-family: MontserratSemiBold, "sans-serif" !important;
  line-height: 20px !important;
  letter-spacing: calc(1em * 0.001 * 20) !important;
  text-transform: uppercase;
}

.thin-scroll::-webkit-scrollbar {
  width: 10px;
}

.tooltip-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  --tw-text-opacity: 0.9 !important;
  color: rgb(31 41 55 / var(--tw-text-opacity)) !important;
  font-family: Montserrat, sans-serif !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  letter-spacing: 0.03em;
}

.tooltip-text__sub {
  font-family: Montserrat, sans-serif !important;
  font-size: 14px !important;
  --tw-text-opacity: 1 !important;
  font-weight: 500 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
  letter-spacing: 0.03em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.tooltip-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.raffle-field>input {
  width: 100%;
  border: 1px solid #bbbbbb;
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
}

.raffle-field>textarea {
  max-width: 492px;
  width: 100%;
  height: 160px;
  border: 1px solid #bbbbbb;
  padding: 12px 20px 12px 20px;
  border-radius: 6px;
  resize: none !important;
}

/* placeholder */

.raffle-field>input::placeholder {
  color: #000000;
}

#startDate::placeholder {
  color: #717070;
  font-size: 14px !important;
  font-weight: 500 !important;
}

#startDate,
.raffle-field-2 {
  max-width: 200px;
  width: 100%;
  border: 1px solid #bbbbbb;
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
}

#endDate {
  max-width: 200px;
  width: 100%;
  border: 1px solid #bbbbbb;
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
  margin-left: 8px;
}

.design_textarea {
  resize: none !important;
  border: none !important;
  line-height: 19px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.upload_borders {
  background-image: repeating-linear-gradient(-23deg,
      #a9a9a9,
      #a9a9a9 14px,
      transparent 14px,
      transparent 30px,
      #a9a9a9 30px),
    repeating-linear-gradient(67deg,
      #a9a9a9,
      #a9a9a9 14px,
      transparent 14px,
      transparent 30px,
      #a9a9a9 30px),
    repeating-linear-gradient(157deg,
      #a9a9a9,
      #a9a9a9 14px,
      transparent 14px,
      transparent 30px,
      #a9a9a9 30px),
    repeating-linear-gradient(247deg,
      #a9a9a9,
      #a9a9a9 14px,
      transparent 14px,
      transparent 30px,
      #a9a9a9 30px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}


textarea {
  line-height: 20px !important;
}

.courier {
  font-family: 'Courier Prime' !important;

}

.courier::placeholder {
  font-family: 'Courier Prime' !important;
}

.background22 {
  background-image: url('../src/assets/images/video-frame.png');
}


.ql-snow {
  .ql-picker {
    &.ql-size {

      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}