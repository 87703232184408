.react-player__preview {
  height: 150px !important;
}

.react-player__shadow {
  display: none !important;
}

.facebookCardVideo__youtube {
  .react-player__preview {
    height: 220px !important;
  }
}

.facebookStoryVideo__youtube {
  .react-player__preview {
    height: 140px !important;
  }
}

.slide-pane__content {
  .react-player__preview {
    height: 100% !important;
  }
}
