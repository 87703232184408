@import "../../styles/colors";

.formDesktopHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 40px;

  a {
    text-decoration: none;
    color: $header-logo;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .link {
    font-family: MontserratMedium, sans-serif;
    font-size: 19px;
    display: flex;
    align-items: center;

    img {
      margin-left: 15px;
    }
  }
}
