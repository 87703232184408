@import "../../styles/media";
@import "../../styles/colors";

@media (max-width: $lg) {
  .playerWrapper {
    padding-top: 56.25% !important;
  }
}

.playerWrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.playerWrapper video {
  border-radius: 5px;
  vertical-align: middle;
}

.playerWrapperV2 {
  padding-top: 72.72%;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

.reactPlayer video:focus {
  outline: none;
}
.videoButton {
  background: none;
  box-shadow: none;
  border: 0;
  opacity: 0;
  position: absolute;
  height: 74px;
  width: 74px;
  padding: 10px;
  left: 50%;
  top: 50%;
  margin-top: -37px;
  margin-left: -37px;
  z-index: 2;
  transition: 0.5s;
}
.showPlayIcon {
  display: block;
  opacity: 1;
}
.playerWrapper:hover,
.playerWrapper:focus,
.playerWrapper:active {
  .videoButton {
    display: block;
    opacity: 1;
  }
}
.videoButton:focus {
  outline: none;
}
.videoPlayIcon {
  background-image: url(../../assets/images/icons/icon-play.svg);
  background-repeat: no-repeat;
  background-size: 50px 54px;
  display: block;
  height: 54px;
  width: 54px;
}
.videoPauseIcon {
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.videoPauseIcon:before,
.videoPauseIcon:after {
  background: $white-color;
  border-radius: 6px;
  content: "";
  display: block;
  width: 12px;
  height: 48px;
}
