@import "../../../styles/colors";
@import "../../../styles/media";

.wrapper {
  width: 950px;
  // margin: auto;

}

.content {
  padding-top: 40px;
  
}

.card {
  max-width: $card-width;
  margin: 0 auto 28px;
  border: 1px solid $campaign-border;
  border-radius: 10px;


  &.mt {
    margin-top: 50px;
  }
  &__big {
    max-width: $card-big-width;
    min-height: $card-big-height;
    display: flex;
    flex-direction: column;
  }
}
.contentFull {
  justify-content: center;
  display: flex;
  flex-direction: column;

  .card {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }

  @media (min-width: $lg) {
    flex-direction: row;

    .card {
      margin-left: 8px;
      margin-right: 8px;
      width: auto;
    }
  }
}
.cardContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  > div {
    flex: 1;
  }
}
.disclaimer {
  align-items: center;
  color: $text-color-3;
  justify-content: center;
  display: flex;
  font-size: 14px;
  // padding: 50px 0;
  flex-grow: 1;

  &__table {
    flex-direction: column;
    text-align: center;
  }
}
.balance {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 35px;
  margin-bottom: 54px;
}
.value {
  font-family: MontserratSemiBold, sans-serif;
  font-size: 30px;
  letter-spacing: 0.5px;
}
.description {
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-top: 8px;
}
.amount {
  text-align: center;
  flex: 1;
  &Container {
    display: inline-block;
    padding: 10px 25px;
    background-color: $settingsIconBg;
    border-radius: 5px;
  }
}
.select {
  flex: 1;
}
.selectA {
  max-width: 250px;
  width: 100%;
  margin-right: -40px;
  margin-top: -27px;

  &__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
  }
}
.selectW {
  max-width: 300px;
}
.payment {
  display: flex;

  .paymentItem:not(:nth-child(1)) {
    margin: 0 0 28px;
    padding-left: 40px !important;
  }
}
.paymentDescription {
  font-size: 12px;
  margin-top: 6px;
  padding-left: 28px;
}
.big {
  // margin-top: 30px;
  font-size: 16px;
  line-height: 26px;
  color: $text-color-3;
}
.paymentImage {
  display: flex;
  justify-content: space-between;

  img {
    transform: translateY(-34px);
  }
}
.error {
  color: $error;
  display: inline-block;
  font-size: 12px;
  transform: translateY(10px);
  padding-left: 6px;
}
.icon {
  width: 100px;
  height: 100px;
  background-color: $settingsIconBg;
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: $box-shadow;
  }

  img {
    width: 47px;
    height: auto;
    vertical-align: middle;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col {
  flex: 1 0 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    font-family: MontserratSemiBold, sans-serif;
    font-size: 16px;
    margin-bottom: 20px;
  }
  p {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    margin-bottom: 16px;
  }
  a {
    font-family: MontserratSemiBold, sans-serif;
    font-size: 14px;
    color: $text-color;
    text-decoration: none;
  }
}
.col_3 {
  flex: 1 0 33%;
  max-width: 33%;
  display: flex;
  flex-direction: column;

  h3 {
    font-family: MontserratSemiBold, sans-serif;
    font-size: 12px;
    margin-bottom: 11px;
  }

  p {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 11px;
  }
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.wallet {
  font-size: 12px;
  line-height: 15px;
  h3 {
    color: $settingsBlue;
    font-family: MontserratSemiBold, sans-serif;
    font-size: 12px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  &__grid {
    display: grid;
    grid-template-columns: 150px auto;
    gap: 10px 0;
    max-width: 380px;
    font-family: MontserratMedium, sans-serif;
  }
  &__note {
    font-family: MontserratMedium, sans-serif;
    margin-top: 20px;
  }
}
