@import "../../../styles/colors";

.label {
  border-radius: 20px;
  color: $white-color;
  font-family: MontserratSemiBold, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  font-size: 12px;
  padding: 2px 10px;
  text-align: center;
  &.active {
    background-color: #f0fdf4;
    color: #15803d;
  }
  &.live {
    background-color: #f0fdf4;
    color: #15803d;
  }
  &.pending {
    background-color: #FCF0C4;
    color: #a16207;
  }
  &.rejected {
    background-color: #FDE1E0;
    color:#dc2626;
  }
  &.finished {
    background-color: #DAE9FE;
    color:#1B52D7;
  }
  &.draft {
    background-color: #ede9fe;
    color:#6d28d9;

  }
  &.stopped {
    background-color: #f9dbcc;
    color:#ee5700;

  }
  &.paused {
    background-color: #ede9fe;
    color:#6d28d9;

  }
  &.deleted {
    background-color: #ede9fe;
    color:#6d28d9;

  }
  &.scheduled {
    background-color: #d3d3d3;
    color: #1e2420;
  }
}
