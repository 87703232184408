//@import './media';
@import "./colors.scss";
@import "./media.scss";

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  width: 100%;

  //@media (min-width: $xl){
  //  max-width: 1360px;
  //  margin: 0 auto;
  //}
}

.stickyContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
}
.stickyContainer_v2{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
}

.rowContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  .main {
    // background-color: $white-color;
    // box-shadow: $box-shadow-main;
    padding-top: 95px;
    overflow: hidden;

  }

  @media (max-width: 640px) {
   .main {
     padding-top: 0 !important;
   }
  }

}
