@import "../../styles/colors";

.loading {
  align-self: center;
  display: inline-block;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;

  &.m {
    width: 80px;
    height: 80px;
    background-color: transparent !important;

    div {
      width: 64px;
      height: 64px;
      margin: 8px;
      border-width: 4px;
      animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
  }

  &.s {
    width: 44px;
    height: 44px;

    div {
      width: 40px;
      height: 40px;
      margin: 4px;
      border-width: 3px;
      animation: loadingS 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
  }

  &.xs {
    width: 40px;
    height: 40px;

    div {
      width: 36px;
      height: 36px;
      margin: 3px;
      border-width: 2px;
      animation: loadingSm 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
  }

  &.white {
    div {
      border-color: $white-color;
    }
  }

  &.violent {
    div {
      // border-color: $violent-color;
      border-color: $walletDarkBlue;
    }
  }
}
.loading div {
  box-sizing: border-box;
  // display: block;
  // position: absolute;
  border-style: solid;
  border-radius: 50%;
  // animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  position: absolute;
  opacity: 1;
}
// .loading div:nth-child(1) {
//   animation-delay: -0.45s;
// }
// .loading div:nth-child(2) {
//   animation-delay: -0.3s;
// }
// .loading div:nth-child(3) {
//   animation-delay: -0.15s;
// }
.loading div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loading {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
@keyframes loadingS {
  0% {
    top: 20px;
    left: 20px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    opacity: 0;
  }
}
@keyframes loadingSm {
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
}
