@import "../../styles/colors";
@import "../../styles/media";

.card {
  // background-color: red;
  min-height: 420px;
  margin-bottom: 20px;
  // padding: 0px 30px 35px 25px;
  padding: 0 15px 35px;
  box-shadow: $box-shadow-big;
  border-radius: 10px;
  color: $white-color;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  cursor: pointer;
  text-align: center;
  align-items: center;

  &__big {
    min-height: 410px;

    .title {
      // width: 80%;
    }
    .pretitle {
      margin-top: auto;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.first {
  grid-column-end: auto;
  // padding-left: 25px;
  // padding-right: 50px;
}

.second {
  grid-column-end: auto;
  // padding-left: 25px;
}

.third {
  grid-column-end: auto;
  grid-row-end: auto;
  // padding-left: 25px;
  // padding-right: 35px;
}

.pretitle {
  font-family: Montserrat, "sans-serif";
  // font-size: 12px;
  font-size: 16px;
  margin-top: auto;
}

.title {
  font-family: MontserratBold, "sans-serif";
  // font-size: 19px;
  // line-height: 23px;
  font-size: 20px;
  line-height: 24px;
  // margin: 3px 0 5px;
  margin: 6px 0 13px;
}

.description {
  font-family: Montserrat, "sans-serif";
  // font-size: 12px;
  // line-height: 17px;
  // letter-spacing: 1.8px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.8px;
}

.arrow {
  display: none;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 15px;
  img {
    width: 32px;
    height: auto;
    vertical-align: middle;
  }

  &__down {
    img {
      transform: rotate(90deg);
    }
  }
}

.video {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  img {
    width: 29px;
    height: auto;
    vertical-align: middle;
  }
}

.logo {
  position: absolute;
  top: 9px;
  left: 22px;
  img {
    width: 50%;
    height: auto;
    vertical-align: middle;
  }
}

@media (min-width: $sm) {
  .card {
    margin-bottom: 0;
    min-height: 250px;
    padding: 0px 20px 25px 20px;

    &__big {
      min-height: 250px;

      .title {
        width: 80%;
      }

      .pretitle {
        margin-top: auto;
      }
    }
  }

  .third + .first {
    grid-column-end: span 2;
  }

  .first {
    padding-left: 20px;
    padding-right: 20px;
  }

  .second {
    padding-left: 20px;

    &:nth-of-type(3) {
      grid-column-end: span 2;

      .pretitle {
        margin-top: auto;
      }
    }
  }

  .third {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pretitle {
    font-size: 11px;
  }

  .title {
    font-size: 15px;
    line-height: 17px;
  }

  .description {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 1.5px;
  }
}

@media (min-width: $md) {
  .card {
    align-items: initial;
    text-align: initial;
    padding: 0px 35px 35px 20px;

    &__big {
      .pretitle {
        margin-top: 200px;
      }

      &:nth-of-type(3) {
        .pretitle {
          margin-top: 200px;
        }
      }
    }
  }

  .first {
    grid-column-end: span 2;
    padding-right: 50px;
  }

  .second {
    grid-column-end: span 1;

    &:nth-of-type(3) {
      grid-column-end: span 1;
    }
  }

  .third {
    grid-column-end: span 1;
    grid-row-end: span 2;
  }

  .arrow {
    display: block;
  }
}

@media (min-width: $ml) {
  .card {
    min-height: 300px;
    padding: 0 30px 45px 30px;
    &__big {
      min-height: 460px;

      .pretitle {
        margin-top: 216px;
      }

      &:nth-of-type(3) {
        .pretitle {
          margin-top: 216px;
        }
      }
    }
  }

  .first {
    padding-left: 30px;
    padding-right: 100px;
  }
  .second {
    padding-left: 30px;
  }
  .third {
    padding-left: 30px;
    padding-right: 45px;
  }

  .pretitle {
    font-size: 13px;
  }
  .title {
    font-size: 23px;
    line-height: 29px;
    margin: 5px 0 10px;
  }
  .description {
    font-size: 14px;
    line-height: 20px;
  }
  .arrow {
    bottom: 22px;
    right: 22px;
    img {
      width: 38px;
    }
  }
  .video {
    top: 22px;
    right: 22px;
  }
}

@media (min-width: $lg) {
  .card {
    min-height: 420px;
    padding: 0 40px 55px 60px;
    &__big {
      min-height: 625px;

      .pretitle {
        margin-top: 360px;
      }

      &:nth-of-type(3) {
        .pretitle {
          margin-top: 360px;
        }
      }
    }
  }

  .first {
    padding-left: 50px;
  }

  .third {
    padding-right: 77px;
  }

  .description {
    font-size: 16px;
    line-height: 22px;
  }

  .title {
    font-size: 26px;
    line-height: 32px;
  }

  .pretitle {
    font-size: 16px;
  }
}
