@import "../../../styles/colors";
@import "../../../styles/media";
@import "../../../styles/mixin";

.tip {
  width: 100%;
  max-width: $new-campaign-width;
  margin: 0 auto;
  &.textTip {
    .content {
      padding-top: 10px;
    }
    .tipLogo {
      display: flex;
      justify-content: flex-end;
      img {
        max-height: 60px;
      }
    }
  }
}

.header {
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  height: 30px;
  text-align: center;
  font-family: MontserratSemiBold, sans-serif;
  font-size: 24px;
  padding: 10px 20px 50px;
  position: relative;
}

.stop {
  .header {
    &:after {
      background-color: $campaign-color;
      border-radius: 5px;
      margin-left: -16px;
      bottom: -16px;
      height: 32px;
      width: 32px;
    }
  }
}

.content {
  background: $white-color;
  position: relative;
  border-radius: 10px;
  box-shadow: $box-shadow;
  color: $text-color-2;
  line-height: 1.25;
  padding: 30px 50px 28px;
  font-family: MontserratSemiBold, sans-serif;

  p,
  ol,
  ul {
    font-family: Montserrat, sans-serif;
    margin: 6px 0 22px;
    font-size: 14px;
    line-height: 20px;
  }

  ol {
    list-style-position: inside;
    margin: 0;
    padding: 0;
  }
}
.headerIcon {
  @include topIcon();
  background: $campaign-color;
  border: 2px solid $white-color;
  box-shadow: $box-shadow;
}
.logoWrapper {
  position: absolute;
  top: 20px;
  right: 50px;
  width: 128px;
}

.popupCloseButton {
  background: $white-color;
  box-shadow: $box-shadow;
  border: none;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: -20px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  span {
    display: block;
    position: relative;
    font-size: 30px;
  }
}
