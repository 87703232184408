@import "../../../styles/colors";
@import "../../../styles/media";
@import "../../../styles/mixin";

.container {
  width: 100%;
  max-width: 812px;
  margin: 0 auto;

  h3 {
    font-family: MontserratSemiBold, sans-serif;
    margin: 0 0 15px;
    font-size: 20px;
  }

  p {
    font-family: Montserrat, sans-serif;
    margin: 0 0 30px;
    font-size: 16px;
  }
}

.content {
  background: $white-color;
  position: relative;
  border-radius: 10px;
  box-shadow: $box-shadow;
  color: $text-color;
  line-height: 1.25;
  padding: 75px 150px 50px;
  font-family: MontserratSemiBold, sans-serif;
  text-align: center;
}

.headerIcon {
  @include topIcon();
  background: $campaign-color;
  border: 2px solid $white-color;
  box-shadow: $box-shadow;
}

.small {
  font-size: 12px;
  margin: 10px 0;
}

.input {
  display: block;
  width: 300px;
  padding: 12px 0 12px 20px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  margin: 0 auto 40px;
  border-radius: 6px;
  border: 1px solid $campaign-color;
}
