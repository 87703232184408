$xs: 23rem; // 368px
$sm: 30rem; // 480px
$sm640: 40rem; //640px
$md: 48rem; // 768px
//$lg: 64rem; // 1024px
//$lg: 68rem; // 1185px
$ml: 58rem; // 928px
$lg: 72rem; // 1152px
//$tablet: 80rem; //1280px
$xl: 90rem; //1440px
$xxl: 100rem; //1600px
$bigMonitor: 112rem; //1792px

$basePadding: 53px;
$baseMargin: 20px;
$baseMarginMobile: 40px;
//$contentWidth: 1564px;

$contentCreatorMargin: 16px;

$contentWidth: 1430px;
$contentWidth-v2: 1366px;
$contentWidth-v3: 1706px;
$contentWidth-v4: 1094px;
//$contentNarrowWidth: 1344px;

$tip-width: 396px;
$content-width: 398px;
$card-width: 674px;
$card-big-width: 850px;
$card-big-height: 650px;
$campaign-width: 700px;
$new-campaign-width: 812px;

$step-width: 300px;
$step-width-md: 350px;

$notifications-header-width: 342px;
$notifications-list-width: 680px;
