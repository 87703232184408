@import "../../../styles/colors";
@import "../../../styles/media";

.errorItem {
  background-color: $white-color;
  border-radius: 10px;
  box-shadow: $box-shadow-notification;
  padding: 23px 25px 25px 33px;
  border-bottom: 8px solid;
  position: relative;
  width: $notifications-header-width;
  text-decoration: none;
  animation: showLeft 0.5s ease;

  &__warning {
    border-color: $notificationsWarning;
  }

  &__error {
    border-color: $notificationsError;
  }

  &__success {
    border-color: $notificationsSuccess;
  }
}

.title {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;

  img {
    margin-right: 12px;
  }
}

.message {
  font-size: 12px;
  line-height: 18px;
  color: $text-color-3;
  margin-top: 16px;
}

.close {
  position: absolute;
  top: 27px;
  right: 27px;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 10px;
    height: auto;
    vertical-align: middle;
  }
}

.list {
  margin-left: 15px;

  li {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    color: $text-color-3;
    margin-bottom: 4px;
    position: relative;
    padding-left: 17px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $error;
      transform: translateY(-50%);
    }
  }
}

@keyframes showLeft {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0%);
  }
}
