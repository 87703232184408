@import "../../styles/colors";

.logo {
  display: flex;

  img {
    margin-right: 7px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.title {
  font-family: Montserrat, sans-serif;
  font-size: 19px;
  margin-bottom: 2px;
}

.subtitle {
  font-family: MontserratBold, sans-serif;
  font-size: 19px;
  text-transform: uppercase;
}

.black {
  .title {
    color: $form-logo-1;
  }

  .subtitle {
    color: $form-logo-2;
  }
}

.white {
  .title {
    color: $white-color;
  }

  .subtitle {
    color: $white-color;
  }
}

.purple {
  .title {
    color: $header-logo;
  }

  .subtitle {
    color: $header-logo;
  }
}
