@import "../../styles/colors";
@import "../../styles/media";

.slidePopUp {
  text-align: center;
  color: $blue-form;
  padding-top: 100px;
  padding-right: 5px;
  padding-bottom: 10px;
  position: relative;
  background: linear-gradient(
    135deg,
    $white-color 0%,
    $form-bg-1 5%,
    $gradient-bg 60%,
    $gradient-bg 100%
  );

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bebdbd;
    border-radius: 10px;
    height: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .slide-pane__content {
    padding: 0 20px 40px 20px;
    position: static;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 36px;
    right: 50%;
    transform: translateX(50%);
  }

  &__overlay {
    z-index: 10;
  }

  &--v1 {
    border-radius: 0 20px 20px 0;
  }

  &--v2 {
    border-radius: 20px 0 0 20px;
    padding-right: 0;
    padding-left: 25px;

    .slide-pane__content {
      direction: rtl;
      padding: 0 145px 90px 120px;
    }

    .slidePopUp__close {
      right: initial;
      left: 30px;
    }
  }

  &--v3 {
    border-radius: 20px 20px 0 0;
    margin-left: auto;
    margin-right: auto;
  }

  &--form {
    padding-top: 67px;
    padding-right: 0;
    .slide-pane__content {
      padding: 0;
    }
    .slidePopUp__close {
      top: 20px;
    }

    .slidePopUp__buttons {
      a {
        width: 100%;
        text-transform: initial;
      }
    }
  }

  &--writeUs {
    height: 75vh;
    margin-top: 25vh;
    padding-top: 100px;

    .slide-pane__content {
      padding: 0 20px;
    }
    .slidePopUp__close {
      top: 36px;
    }
  }

  &__video {
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: $box-shadow;
  }

  &__image {
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  &__pretitle {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 14px;
  }

  &__header {
    margin: 8px 0 15px;
    font-family: MontserratExtraBold;
    font-size: 20px;
  }

  &__title {
    font-family: MontserratBold;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 0;

    &-small {
      font-family: MontserratSemiBold;
      text-transform: initial;
      margin: 15px 0;
    }
  }

  &__subtitle {
    font-family: MontserratMedium;
    font-size: 22px;
    margin-top: 50px;
    margin-bottom: 0;
  }

  &__icon {
    margin-top: 50px;
  }

  &__text {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line;

    span {
      font-family: MontserratBold;

      &.slidePopUp__blue {
        color: $slide-blue;
        font-family: Montserrat;
        font-style: italic;
        width: 90%;
        margin: auto;
        display: block;
      }

      &.slidePopUp__aqua {
        color: $slide-link;
        font-family: Montserrat;
        font-style: italic;
      }

      &.slidePopUp__btn {
        font-family: Montserrat;
      }
    }

    &-bold {
      margin-bottom: 0;
      font-family: MontserratBold;
    }
  }

  &__btn {
    cursor: pointer;
    font-family: Montserrat;
    font-size: 16px;
    font-style: italic;
    color: $slide-link;
  }

  &__logos {
    display: none;
    justify-content: space-between;
    width: 415px;
    margin: 80px auto 0;

    img {
      height: 40px;
      width: auto;
      vertical-align: middle;

      &:nth-of-type(1) {
        height: 30px;
      }
      &:nth-of-type(4) {
        height: 26px;
      }
    }
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 90px 0 0;

    img {
      height: auto;
      vertical-align: middle;
      margin: 10px 5px 0;

      &:nth-of-type(1) {
        width: 159px;
        order: 1;
      }
      &:nth-of-type(2) {
        width: 135px;
        order: 3;
      }
      &:nth-of-type(3) {
        width: 130px;
        order: 5;
      }
      &:nth-of-type(4) {
        width: 89px;
        order: 2;
      }
      &:nth-of-type(5) {
        width: 110px;
        order: 4;
      }
    }
  }

  &__items {
    margin: 0;
  }

  &__item {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__list {
    &-title {
      font-family: MontserratMedium;
      font-size: 14px;
      margin: 0 0 9px;
      color: $text-color;
    }

    &-text {
      p {
        font-family: Montserrat;
        font-size: 12px;
        margin: 0 0 9px;
        color: $text-color;
      }
    }

    &-socials {
      img {
        height: 15px;
        width: auto;
        vertical-align: middle;
        margin-right: 15px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__logo {
  }

  @media (min-width: $sm) {
    &__logos {
      display: flex;
    }

    &__images {
      width: 440px;
      margin: 90px auto 0;
      justify-content: center;

      img {
        margin: 0;
        &:nth-of-type(1) {
          width: 159px;
          order: initial;
        }
        &:nth-of-type(2) {
          margin-left: 20px;
          order: initial;
        }
        &:nth-of-type(3) {
          width: 130px;
          order: initial;
        }
        &:nth-of-type(4) {
          order: initial;
          margin: 10px 20px 0;
        }
        &:nth-of-type(5) {
          order: initial;
          margin-top: 10px;
        }
      }
    }

    &__pretitle {
      font-size: 16px;
    }

    &__header {
      margin: 8px 0 21px;
      font-size: 26px;
    }

    &__title {
      font-size: 22px;
    }

    &__subtitle {
      font-size: 22px;
    }

    &__text {
      font-size: 18px;
      line-height: 26px;
    }
  }

  @media (min-width: $md) {
    padding-top: 70px;
    padding-right: 25px;
    background: $white-color;

    .slide-pane__content {
      padding: 0 120px 90px 145px;
    }

    &__close {
      top: 24px;
      right: 30px;
      transform: translateX(0);
    }

    &__video {
      width: 500px;
    }

    &__items {
      margin: 0 -35px;
    }

    &__item {
      width: 47%;
    }

    &__images {
      width: 500px;

      img {
        &:nth-of-type(2) {
          margin: 0 32px;
        }
        &:nth-of-type(4) {
          margin-top: 35px;
          margin-right: 38px;
        }
        &:nth-of-type(5) {
          margin-top: 35px;
          margin-left: 38px;
        }
      }
    }
  }
}

.ReactModal__Body--open {
  overflow: hidden !important;
}
