@import "../../styles/colors";

.checkbox {
  margin-bottom: 5px;
  &__mb0 {
    margin-bottom: 0;
  }
  > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin-bottom: 0;
    left: -1000px;

    &:checked + label .main:after {
      content: "";
    }

    &:focus + label .main:not(.round) {
      outline: none;
    }
  }
  label {
    font-family: MontserratMedium, sans-serif;
    font-size: 16px;
    user-select: none;
    position: relative;
    display: flex;
    color: $text-color;

    .main,
    .main:after {
      border-radius: 3px;
      display: block;
    }

    .main:after {
      padding-right: 13px;
      inset: 0;
      margin: 0 auto;
      position: absolute;
      /* left: 0.55mm; */
      top: 1.3pt;
      height: 12px;
      width: 12px;
    }

    .main {
      border-style: solid;
      border-width: 2.5px;
      border-radius: 5px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      height: 20px;
      margin-right: 10px;
      min-width: 20px;
      width: 20px;

      &.round,
      &.round:after {
        border-radius: 50%;
      }

      &.error {
        border-color: $error;
      }
    }
  }
}
.checkboxV2 {
  label {
    padding-left: 33px;
    .main:after {
      transition: left 0.2s ease;
      content: "";
    }
    .main {
      width: 26px;
    }
  }
  input:checked + label .main:after {
    left: 8px;
  }
}

.checkboxV3 {
  label {
    .main:after {
      border-radius: 0;
      background: transparent;
      border-style: solid;
      border-width: 0 2px 2px 0;
      height: 12px;
      width: 6px;
      transform: rotate(45deg);
      left: 4px;
      top: -1px;
    }
  }
}

.checkboxV4 {
  label {
    .main {
      width: 15px;
      height: 15px;
      margin-right: 7px;
      border-width: 1px;
      border-radius: 3px;
      min-width: initial;

      &:after {
        left: 2px;
        top: 2px;
        height: 9px;
        width: 9px;
      }
    }
  }
}

.radio {
  display: flex;
  > div:not(:first-child) {
    margin-left: 25px;
  }
}
.violent {
  label {
    // color: $violent-color;
    color: $text-color;
  }

  .main {
    border-color: $violent-color;
  }

  .main:after {
    background: $violent-color;
    border-color: $violent-color;
  }
}
.blue {
  .main {
    background: $background-color;
    border-color: $blue-color;
    &:hover {
      cursor: pointer;
    }
  }

  .main:after {
    background: $blue-color;
    border-color: $blue-color;
  }
}
.darkblue {
  color: $blue-form-dark;

  label {
    font-family: MontserratLight, sans-serif;
    font-size: 12px;
  }

  .main {
    background: $background-color;
    border-color: $blue-form;
    &:hover {
      cursor: pointer;
    }
  }

  .main:after {
    background: $blue-form;
    border-color: $blue-form;
  }
}
.black {
  color: $text-color;

  label {
    .main {
      background-color: $light-grey;
      border-color: transparent;
      cursor: pointer;

      &:after {
        background: $text-color;
      }
    }
  }
}
.grey {
  color: $text-color-2;

  label {
    color: $text-color-2;

    .main {
      width: 20px;
      height: 20px;
      margin-right: 10px;

      &:after {
        background: $text-color-2;
        border-color: $text-color-2;
        width: 14px;
        height: 14px;
      }
    }
  }
}
