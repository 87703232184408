@import "../../styles/colors";
@import "../../styles/media";

.formWrapper {
  background: $white-color;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative;
  // max-width: 614px;
  padding: 20px;

  @media (min-width: $sm) {
    padding: 50px;
  }

  @media (min-width: $lg) {
    // box-shadow: $box-shadow;
    margin: 0 auto;
    padding: 83px 113px 0px 132px;
  }

  &__contact {
    background-color: transparent;
    padding: 20px;

    @media (min-width: $sm) {
      padding: 30px;
    }

    @media (min-width: $md) {
      padding: 64px 93px 54px 66px;
    }
  }

  &__popup {
    background-color: transparent;

    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea {
      background-color: transparent;
    }
  }

  &__registration {
    // padding: 46px 17px 40px;
    padding: 0;
    background: transparent;

    @media (min-width: $md) {
      // padding: 50px;
      padding: 0;
    }

    @media (min-width: $lg) {
      // box-shadow: $box-shadow;
      margin: 0 auto;
      // padding: 83px 113px 0px 132px;
      padding: 0;
    }

    .subtitle {
      max-width: 300px;
      @media (min-width: $md) {
        max-width: 100%;
      }
    }

    .form {
     
      width: 500px;

      @media (max-width: 520px) {
        width: auto;
        padding: 0 16px;
      }

      input[type="text"],
      input[type="email"],
      input[type="password"],
      textarea {
        border: none;
        color: $text-color-2;
        line-height: 70px;
        font-size: 20px;
        height: 70px;
        padding: 0 20px;
        margin-bottom: 25px;

        &:focus {
          outline: none;
          border-color: $blue-bright-color;
        }

        &::placeholder {
          color: $text-color-2;
          font-size: 20px;
        }
      }

      .title {
        font-size: 40px;
        text-transform: none;
        color: $header-logo;
        text-align: center;
        margin: 0 0 30px;
        font-family: MontserratSemiBold, sans-serif;
        letter-spacing: 1px;

        &_mb {
          margin-bottom: 16px;
        }

        // &_rec {
        //   margin-bottom: 38px;
        // }

        @media (min-width: $sm) {
          font-size: 40px;
        }
      }
    }
  }
}
.inner {
  position: relative;
}
.form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;

  @media (min-width: $md) {
    width: 300px;
  }

  input,
  button,
  textarea {
    margin-bottom: 20px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    border: 1px solid $blue-form;
    border-radius: 10px;
    color: $blue-form-dark;
    line-height: 42px;
    font-size: 0.875rem; //14px
    height: 40px;
    padding: 0 20px;

    &:focus {
      outline: none;
      border-color: $blue-bright-color;
    }

    &::placeholder {
      color: $blue-form;
      font-size: 0.875rem; //14px
    }
  }

  textarea {
    font-family: Montserrat, sans-serif;
    height: 105px;
  }

  .input {
    position: relative;

    input {
      width: 100%;
    }

    &.invalid input {
      border-color: $error;
    }

 
  }

  &__contact {
    width: 100%;

    input,
    button {
      margin-bottom: 15px;

      background-color: transparent;
      font-family: MontserratLightItalic, sans-serif;
    }

    textarea {
      margin-bottom: 30px;

      background-color: transparent;
      font-family: MontserratLightItalic, sans-serif;
    }
  }

  @media (min-width: $sm640) {
    &__contact {
      width: 300px;

      textarea {
        margin-bottom: 80px;
      }
    }
  }

  @media (min-width: $md) {
    &__contact {
      width: 400px;
    }
  }
}

.formLogo {
  align-self: center;
  margin-bottom: 32px;
}

.email {
  &:after {
    background-image: url(../../assets/images/icons/icon-email.svg);
    background-size: 24px;
    height: 24px;
    top: 9px;
    right: 10px;
    width: 24px;
    z-index: 10;
  }
}

.password {
  &:after {
    background-image: url(../../assets/images/icons/icon-lock.svg);
    background-size: 24px;
    height: 24px;
    top: 9px;
    right: 10px;
    width: 24px;
    z-index: 10;
  }
}
.remember {
  align-items: center;
  display: flex;
  font-family: MontserratLight, sans-serif;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-left: 20px;

  a {
    color: $text-color-2;
    font-size: 16px;
    // text-decoration: none;
    text-decoration: underline;
    font-family: MontserratMedium, sans-serif;

    // &:hover {
    //   text-decoration: underline;
    // }
  }

  &__forgot {
    justify-content: center;
    margin-bottom: 0;
    padding-left: 0;
  }

  @media (min-width: $md) {
    padding-left: 0;

    &__forgot {
      justify-content: space-between;
      margin-bottom: 30px;
    }
  }
}
.footer {
  justify-content: center;
  display: flex;
  margin-top: 37px;

  @media (min-width: $lg) {
    margin-top: 84px;
  }
}

.checkboxes {
  padding-left: 20px;
  margin-bottom: 20px;

  @media (min-width: $md) {
    padding-left: 10px;
  }
}

.title {
  font-size: 20px;
  text-transform: uppercase;
  color: $blue-form;
  text-align: center;
  margin: 0 0 28px;
  font-family: MontserratExtraBold, sans-serif;
  letter-spacing: 1px;

  &_mb {
    margin-bottom: 16px;
  }

  // &_rec {
  //   margin-bottom: 38px;
  // }

  @media (min-width: $sm) {
    font-size: 26px;
    margin: 0 -15px 28px;
  }
}

.subtitle {
  font-family: MontserratMedium, sans-serif;
  font-size: 18px;
  line-height: 29px;
  color: $text-color-2;
  // text-align: center;
  margin-bottom: 31px;

  &_rec {
    margin-top: 75px;
    margin-bottom: 75px;
  }
}

.error {
  color: $error;
  display: inline-block;
  font-size: 12px;
  transform: translateY(-20px);
  padding-left: 20px;
}
.errorMessage {
  color: $error;
  margin-bottom: 20px;
}
.popup {
  justify-items: center;
  background: $white-color;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 0 2px;
  top: 0;
  left: 10%;
  height: 100%;
  justify-content: center;
  z-index: 11;
//   create media queries for screen less than 550px
  @media (max-width: 550px) {
    left: 0;
    
  }
}
.popupText {
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: -10%;
}
.linkIcon {
  background: $campaign-color;
  border-radius: 50%;
  display: inline-block;
  align-self: center;
  height: 50px;
  width: 50px;
  position: relative;

  &:before,
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
  }

  &.close {
    &:before,
    &:after {
      background: $white-color;
      border-radius: 2px;
      height: 4px;
      margin-top: 50%;
      top: -2px;
      margin-left: 50%;
      left: -13px;
      width: 26px;
    }

    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
.link {
  color: $blue-form-dark;
  text-decoration: none;
  position: absolute;
  bottom: 28px;
  right: 44px;
}
.submit {
  align-self: center;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 5px !important;
}

.agree {
  text-align: center;
  color: $text-color-2;
  font-family: MontserratMedium, sans-serif;
  font-size: 14px;
  line-height: 25px;
  margin-top: 15px;

  a {
    color: $text-color-2;
    text-decoration: underline;
  }
}
.back {
  align-self: center;
  padding-left: 35px;
  padding-right: 35px;
}
.aftertitle {
  margin: 15px 0 40px;
  text-align: center;
  font-family: MontserratMedium, sans-serif;
  font-size: 16px;
  color: $text-color-2;
}
