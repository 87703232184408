@import "../../styles/media";
@import "../../styles/colors";

.logo {
  display: flex;
  align-items: center;
  img {
    max-height: 100%;
    margin-right: 6px;
  }

  &__center {
    margin-right: 0;
  }

  &__header {
    .title {
      display: none;
      @media (min-width: $lg) {
        display: initial;
      }
    }
    img {
      width: 50px;
      height: auto;
      max-height: initial;
      margin-top: 6px;
      @media (min-width: $lg) {
        margin-top: 0;
      }
    }
  }

  &__leftPanel {
    .title {
      font-size: 19px;
    }

    .subtitle {
      font-size: 19px;
    }

    img {
      width: 55px;
      height: auto;
      max-height: initial;
      margin-top: 3px;
    }
  }
}

.text {
  color: $header-logo;
  font-size: 16px;

  &__first {
    display: none;
  }

  &__black {
    color: $text-color;
  }
}

.title {
  font-family: Montserrat, "sans-serif";
}

.subtitle {
  font-family: MontserratBold, "sans-serif";
}

@media (min-width: $md) {
  .text {
    &__first {
      display: block;
    }
  }
}

@media (min-width: $lg) {
  .logo {
    &__center {
      margin-right: -63px;
    }
  }
}
