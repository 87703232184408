@import '../../styles/colors';
@import '../../styles/media';

.closeButton {
  background: transparent;
  margin-left: auto;
  margin-right: 10px;
  border: 0;
  padding: 10px;
  height: 41px;
  min-width: auto;
  width: 41px;

  &:focus {
    outline: $focus;
  }

  span {
    display: block;
    height: 21px;
    position: relative;
    width: 21px;
    &:before,
    &:after {
      background: $text-color;
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      width: 28px;
      top: 10px;
      left: -3px;
    }

    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  &.light {
    span {
      &:before,
      &:after {
        background: $white-color;
      }
    }
  }

  &.small {
    height: 38px;
    width: 38px;
    span {
      height: 18px;
      width: 18px;

      &:before,
      &:after {
        width: 24px;
      }
    }
  }

  &.revert {
    height: 50px;
    width: 50px;
    span {
      background-image: url(../../assets/images/icons/icon-close-circle.svg);
      background-size: 30px 30px;
      height: 30px;
      width: 30px;
      &:before,
      &:after {
        display: none;
      }
    }
  }

  @media (min-width: $lg) {
    &.desktopHide {
      display: none;
    }
  }
}
