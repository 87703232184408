@import "../../../styles/colors";
@import "../../../styles/media";

.card-content {
  background: $white-color;
  padding: 22px 20px 20px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1.2;
border-radius: 6px !important;
  &--no-padding {
    padding: 0 0 18px;
    .card-content__row {
      margin: 0;
    }
  }
  &--transparent {
    background-color: transparent;
  }
  &--column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &--padding {
    padding-left: 100px;
    padding-right: 100px;
  }
  &--noTopPadding {
    padding-top: 0;
  }
  &--full {
    height: 100%;
  }

  p {
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 20px;
    margin-top: 0;
  }

  h3 {
    font-size: 16px;
    font-weight: bolder;
    letter-spacing: 0;
    margin-bottom: 5px;
    margin-top: 0;
  }

  textarea {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    border-radius: 5px;
    border:1px solid #B8B8B8;
    width: 100%;
    min-height: 120px;
    padding: 16px 20px;
    resize: none;
    max-width: 572px;
    &:focus {
      //outline: $focus;
    }

    &::placeholder {
      color: $placeholder-color;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
    }

    &.error {
      border-color: $error;
    }
  }

  input[type="text"]:not([tabindex="0"]):not(.ReactTags__tagInputField),
  input[type="password"]:not([tabindex="0"]),
  input[type="number"]:not([tabindex="0"])
  {
    border-radius: 6px;
    border: 1px solid #BBBBBB;
    height: 42px;
    line-height: 42px;
    padding-left: 20px;
    width: 100%;

    &::placeholder {
      color: #000;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
    }

    &:focus {
      //outline: $focus;
    }

    &[disabled] {
      background: #fff;
    }

    &.error {
      border-color: #CF151B;
      border-width: 1px;
    }
  }

  #price-per-post {
    vertical-align: middle;

    &:focus,
    &:not([value=""]) {
      padding-left: 32px;
      & + label {
        &::before {
          // content: "";
        }
      }
    }
    

    & + label {
      display: inline-block;
      vertical-align: middle;
      font-family: Montserrat;

      &::before {
        position: absolute;
        left: 22px;
        top: 12px;
      }
    }
  }
  

  button {
    height: 100%;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
    &-no-padding {
      margin: 0;
      & > .card-content__col {
        padding: 0;
      }
    }
    &-negative {
      margin: 0 -30px;
      & > .card-content__col,
      & > .card-content__col--full {
        padding: 0 30px;
      }
    }
    &-padding {
      margin: 0 -50px;
      & > .card-content__col,
      & > .card-content__col--full {
        padding: 0 50px;
      }
    }
    &-bigpadding {
      margin: 0 -80px;
      & > .card-content__col,
      & > .card-content__col--full {
        padding: 0 80px;
      }
    }
    &-column {
      flex-direction: column;
      max-height: 800px;
      & > .card-content__col:not(.card-content__col--full) {
        flex: 0 0 50%;
      }
    }
    &-age {
      margin: 0 -15px;
      & > .card-content__col {
        padding: 0 15px;
      }
    }
    &-center {
      justify-content: center;
    }
    &-pt {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &-sb {
      justify-content: space-between;
    }
    &-auto {
      margin: auto;
    }
  }

  &__col,
  &__col--md,
  &__col--full {
    padding: 0 6px;
    flex: 1 0 100%;
    max-width: 100%;
    margin: 0 0 16px;

    button,
    input[type="file"] + label {
      width: 100%;
      &.react-datepicker__navigation {
        width: 32px;
        height: 32px;
      }
    }

    &.toBottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__col--platform {
    max-width: 515px;
    margin-left: auto;
    margin-right: auto;
  }

  &__col--flex {
    display: flex;
    flex-direction: column;
  }

  &__col-mb0 {
    margin-bottom: 0;
  }

  &__col-center {
    display: flex;
    align-items: center;
  }

  &__col-jcc {
    display: flex;
    justify-content: center;
  }

  &__row--f {
    .card-content__col {
      flex: 1 0 50%;
      max-width: 50%;
    }
  }

  &__description {
    color: $text-color-3;
    font-family: Montserrat, sans-serif;
    text-align: center;
  }

  &__block {
    border-radius: 10px;
    padding: 20px 30px 10px;

    &-hint {
      padding-top: 30px;
    }
  }

  &__cyan {
    background-color: $light-cyan;
  }

  &__grey {
    background-color: $light-grey-blue;
  }

  &--wide {
    margin: 0 -40px;
    .card-content__col {
      padding-left: 46px;
    }
  }

  &__hint {
    position: absolute;
    top: -26px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    display: flex;
    width: 52px;
    height: 52px;
    box-shadow: $box-shadow;
    background-color: $violent-color;
    border: 3px solid $white-color;

    img {
      width: 50%;
      height: 50%;
      margin: auto;
      vertical-align: middle;
    }
  }

  &__advice {
    display: none;
    position: absolute;
    bottom: 60px;
    // left: -225px;
    right: -240px;
    width: 200px;
  }

  @media (min-width: $md) {
    &__col--padding-inside {
      padding: 0 165px;
    }

    &__col--md {
      &:not(.card-content__col--full) {
        flex: 1 0 50%;
        max-width: 50%;
      }

      .card-content__col {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: $lg) {
    &__advice {
      display: block;
    }
  }

  @media (min-width: $xl) {
    &__col {
      &:not(.card-content__col--full) {
        flex: 1 0 50%;
        max-width: 50%;
      }

      &-35 {
        &:not(.card-content__col--full) {
          flex: 1 0 35%;
          max-width: 35%;
        }
      }

      &-40 {
        &:not(.card-content__col--full) {
          flex: 1 0 40%;
          max-width: 40%;
        }
      }

      &-43 {
        &:not(.card-content__col--full) {
          flex: 1 0 43%;
          max-width: 43%;
        }
      }

      &-46 {
        &:not(.card-content__col--full) {
          flex: 1 0 46%;
          max-width: 46%;
        }
      }

      &-47 {
        &:not(.card-content__col--full) {
          flex: 1 0 47%;
          max-width: 47%;
        }
      }

      &-54 {
        &:not(.card-content__col--full) {
          flex: 1 0 54%;
          max-width: 54%;
        }
      }

      &-57 {
        &:not(.card-content__col--full) {
          flex: 1 0 57%;
          max-width: 57%;
        }
      }

      &-95 {
        &:not(.card-content__col--full) {
          flex: 1 0 95%;
          max-width: 95%;
        }
      }

      .card-content__col {
        margin-bottom: 0;
      }
    }

    &__description {
      padding-left: 66px;
      padding-right: 66px;
    }
  }
}



.ReactTags__tagInputField {
  display: inline-block;
  padding: 8px 12px;
}

.tag-wrapper .ReactTags__tag {
  display: flex;
}


.ReactTags__tagInputField:focus {
  outline: none;
}

.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
