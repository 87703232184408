@import "../../../styles/colors";
@import "../../../styles/media";

.notificationsHeader {
  position: fixed;
  top: 25px;
  right: 150px;
  z-index: 105;
}

.image {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: $header-bg;
  position: relative;
  margin-left: auto;
  cursor: pointer;

  img {
  }

  &__circle {
    position: absolute;
    top: 7px;
    right: 8px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: $notificationsBlue;
  }
}

.notificationsList {
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  & > div {
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}



.leftPanel {
  min-width: 250px;
  //   height: 100%;
  background-color: $dashboardCardBg;
  // z-index: 5;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    position: fixed;
    min-width: 250px;
  }
}

.headerLogo {
  //   height: 34px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: -10px;
  outline: none;

  &:focus {
    outline: none;
  }

  > div {
    height: 100%;
  }

  @media (min-width: $lg) {
    // height: 53px;
  }
}

.info {
  border-top: 1px solid $leftPanelBorder;
  color: $leftPanelText;
  position: relative;

  &__active {
    &:hover {
      background-color: $leftPanelActiveBg;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 137px;
    cursor: pointer;
  }

  &__modal {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 61px;
    right: 0px;
    z-index: 10;
    width: 255px;
    background-color: $white-color;
    padding: 29px 0 29px 0;
    // media query for screens smaller than sm
    @media (max-width: 640px) {
      top: -620px;
     
    }

    .info {
      &__group {
        margin-right: 0;
      }
    }

    &Container {
      padding: 0 26px 0 33px;
      border-bottom: 1px solid $leftPanelBorder;
    }

    &Active {
      display: flex;
    }
    &Info {
      display: flex;
      align-items: center;
    }
    &Email {
      margin-top: 17px;
      margin-bottom: 25px;
      font-family: MontserratMedium, sans-serif;
      font-size: 14px;
    }
    &Links {
      padding-bottom: 21px;
      li {
        padding-top: 18px;
        a {
          font-family: MontserratMedium, sans-serif;
          text-decoration: none;
          color: $leftPanelText;
        }
      }
    }
    &Logout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 26px 0 33px;
      font-family: MontserratSemiBold, sans-serif;
      font-size: 14px;
      cursor: pointer;

      img {
      }
    }
  }

  &__border {
    display: block;
    width: 14px;
    height: 45px;
    border-radius: 10px;
    background-color: $leftPanelInfo;
  }

  &__group {
    display: flex;
    flex-direction: column;
    margin: 0 21px 0 13px;
  }

  &__title {
    font-family: MontserratSemiBold, sans-serif;
    font-size: 14px;
    margin: 0 0 5px;
  }

  &__text {
    font-family: MontserratMedium, sans-serif;
    font-size: 14px;
  }

  &__img {
    img {
    }
  }
}

.wallet {
  width: 210px;
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient($walletBlue, $walletDarkBlue);
  padding: 25px 0;
  border-radius: 10px;
  color: $white-color;

  &_bottom {
    margin-top: auto;
  }

  &__title {
    margin: 14px 0;
    font-family: MontserratMedium, sans-serif;
    font-size: 16px;
  }

  &__balance {
    font-family: MontserratSemiBold, sans-serif;
    font-size: 30px;
    margin-bottom: 27px;
  }
  &__link {
    color: $white-color;
    margin-top: 15px;
    font-family: MontserratMedium, sans-serif;
    &:focus {
      outline: none;
    }
  }
}

