@import "../../styles/colors";
@import "../../styles/media";

.footerMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: auto 0 0 0;

  li {
    margin: 0 10px 5px;
    display: flex;
  }

  a,
  .link {
    font-size: 16px;
    color: $footer-text;
    text-decoration: none;
    cursor: pointer;
    font-family: "MontserratLight", sans-serif;
  }

  @media (min-width: $lg) {
    margin: auto 0 0 62px;

    li {
      margin: 0 15px;
    }

    a,
    .link {
      transition: color 0.5s ease;

      &:hover {
        color: $violent-color;
      }
    }
  }

  @media (min-width: $sm) {
    li {
      margin: 0 10px;
    }
  }
}
.footerMenuActive {
  a,
  .link {
    color: $blue-bright-color;
    text-decoration: underline;
  }

  @media (min-width: $lg) {
    a,
    .link {
      color: $grey-color;
    }
  }
}

.mobileHide {
  display: none;
  @media (min-width: $lg) {
    display: inherit;
  }
}

.mobileMode {
  @media (min-width: $lg) {
    li {
      margin: 0 2.5px;
    }

    a,
    .link {
      font-size: 0.75rem;
      color: $blue-color;
      text-decoration: none;

      &:hover {
        color: $grey-color;
      }
    }

    .mobileHide {
      display: none;
    }
  }
}
