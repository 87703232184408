@import "../../styles/colors";
@import "../../styles/media";

.button {
  border: 2px solid $violent-color;
  border-radius: 5px;
  background: $violent-color ;
  box-shadow: none;
  color: $white-color;
  font-family: Montserrat, sans-serif;
  display: inline-block;
  font-size: 1rem;
  padding: 9px;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  transition: all 0.5s ease;
  min-width: 140px;
  line-height: normal;
  max-width: 100%;

  &.bold {
    font-family: MontserratSemiBold, sans-serif;
  }

  &:hover {
    background-color: transparent ;
    color: $violent-color ;
    cursor: pointer ;
  }

  &:focus {
    outline: $focus;
  }

  &.outline {
    background: transparent;
    color: $violent-color;

    &:hover {
      background-color: $violent-color;
      color: $white-color;
    }
  }

  &.shadow {
    box-shadow: $box-shadow;
  }

  &.white {
    background: $white-color;
    color: $text-color;
    border-color: $white-color;

    &:hover {
      background-color: $black-squeeze-color;
      border-color: $black-squeeze-color;
      color: $text-color;
    }
  }

  &.white-bordered {
    background: $white-color;
    color: $text-color;
    border-color: $text-color;

    &:hover {
      background-color: $black-squeeze-color;
      border-color: $text-color;
      color: $text-color;
    }
  }

  &.white-shadow {
    background: $white-color;
    color: $blue-form;
    border-color: $white-color;
    text-transform: uppercase;
    border-radius: 10px;
    box-shadow: $box-shadow;

    &:disabled {
      background: $white-color;
      border-color: $white-color;
    }
  }

  &.white-shadow-small {
    background: $white-color;
    color: $text-color;
    border-color: $white-color;
    border-radius: 6px;
    box-shadow: $box-shadow;
  }

  &.blue-shadow {
    border-radius: 10px;
    background: $facebook-color;
    color: $white-color;
    border-color: $facebook-color;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  }

  &.blue {
    border-radius: 30px;
    background-color: $facebook-color;
    border-color: $facebook-color;
    color: $white-color;

    &:disabled {
      background-color: $facebook-color;
      border-color: $facebook-color;
      color: $white-color;
    }
  }

  &.transparent {
    background: transparent;
    color: $white-color;
    border-color: $white-color;

    &:hover {
      background-color: $white-color;
      border-color: $text-color-2;
      color: $text-color-2;
    }
  }

  &.white.outline {
    background: transparent;
    color: $white-color;
    border-color: $white-color;

    &:hover {
      background-color: $white-color;
      color: $text-color;
    }
  }

  &.red {
    background: $red-color;
    color: $white-color;
    border-color: $red-color;

    &:hover {
      background-color: transparent;
      color: $red-color;
    }
  }

  &.red.outline {
    background: transparent;
    color: $red-color;
    border-color: $red-color;

    &:hover {
      background-color: $red-color;
      color: $white-color;
    }
  }

  &.orange {
    background: $orange-color;
    color: $white-color;
    border-color: $orange-color;

    &:hover {
      background-color: transparent;
      color: $orange-color;
    }
  }

  &.orange.outline {
    background: transparent;
    color: $text-color;
    border-color: $orange-color;

    &:hover {
      background-color: $orange-color;
      color: $white-color;
    }
  }

  &.purple {
    background: $purple-color;
    color: $white-color;
    border-color: $purple-color;

    &:hover {
      background-color: transparent;
      color: $purple-color;
    }
  }

  &.purple.outline {
    background: transparent;
    color: $text-color;
    border-color: $purple-color;

    &:hover {
      background-color: $purple-color;
      color: $white-color;
    }
  }

  &.green {
    background: $grass-color;
    color: $white-color;
    border-color: $grass-color;

    &:hover {
      background-color: transparent;
      color: $grass-color;
    }
  }

  &.green.outline {
    background: transparent;
    color: $text-color;
    border-color: $grass-color;

    &:hover {
      background-color: $grass-color;
      color: $white-color;
    }
  }

  &.cyan {
    background: $cyan;
    color: $text-color;
    border-color: $cyan;

    &:hover {
      background-color: transparent;
      color: $cyan;
    }
  }

  &.pink {
    background: $red-pink;
    color: $text-color;
    border-color: $red-pink;

    &:hover {
      background-color: transparent;
      color: $red-pink;
    }
  }

  &.light-green {
    background: $light-green;
    color: $text-color;
    border-color: $light-green;

    &:hover {
      background-color: transparent;
      color: $light-green;
    }
  }

  &.fullWidth {
    font-size: 20px;
    padding-bottom: 26px;
    padding-top: 26px;
    width: 100%;
  }

  &.s,
  &.m {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &.sm {
    padding-top: 9px;
    padding-bottom: 9px;
    border-width: 0.5px;
  }

  &.l {
    padding: 9px 0;
  }

  &.md {
    padding-top: 14px;
    padding-bottom: 14px;
    min-width: 250px;
  }

  &.lg {
    padding: 15px 0;
    font-size: 16px;
    width: 100%;
  }

  @media (min-width: $sm) {
    min-width: 200px;

    &.s {
      min-width: 150px;
    }

    &.l {
      min-width: 300px;
    }

    &.lg {
      padding: 22px 0;
      font-size: 22px;
      min-width: 300px;
    }

    &.sm {
      min-width: 150px;
    }
  }

  &.xs {
    border-width: 1px;
    font-size: 9px;
    font-family: Montserrat, sans-serif;
    min-width: 120px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.m {
    min-width: 100px;
  }

  &[disabled] {
    border-color: $silver-color;
    background-color: $silver-color;
    pointer-events: none;

    &.outline {
      background-color: transparent;
    }
  }
}

.modal {
  display: flex;
  justify-content: center;
}

.modalWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 24px;
  }

  @media (min-width: $md) {
    padding-top: 80px;
  }
}

.modalIcon {
  margin-right: 10px;
  height: 28px;
  width: 28px;
}

.modalTitle {
  align-items: center;
  display: flex;
  font-size: 1rem; //16px
  letter-spacing: 0.8px;
  margin-bottom: 40px;
  width: 100%;

  @media (min-width: $md) {
    font-size: 1.5rem; //24px
    width: auto;
  }
}
