@import "../../styles/colors";
@import "../../styles/media";

.formWrapper {
  background: transparent;
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding: 0;

  @media (min-width: $xs) {
  }

  @media (min-width: $md) {
    height: 100vh;
    overflow-y: auto;
    padding: 0;
    flex-direction: row;
    margin-bottom: 0;
    align-items: center;

    background-image: url(../../assets/images/login-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  @media (min-width: $lg) {
    align-items: center;
  }
}
.formWrapperImage {
  // display: none;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
  }

  img {
    width: 100%;
    vertical-align: middle;
  }

  @media (min-width: $lg) {
    display: block;

    &::after {
      background: linear-gradient(
        rgba(255, 255, 255, 0) 60%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
  }
}
.formWrapperForm {
  align-items: center;
  display: flex;
  width: 100%;
  @media (min-width: $md) {
    width: initial;
    // margin-left: -64px;
  }
}

.formWrapperBlock {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  //   box-shadow: $box-shadow-big;
  margin: 0 10px;
  flex-direction: column;

  @media (min-width: $xs) {
    margin: 0 auto;
  }

  @media (min-width: $md) {
    margin: 0;
    flex-direction: row;
  }
}

.formLogo {
  display: flex;
  position: absolute;
  z-index: 15;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);

  a {
    color: $white-color;
    text-decoration: none;
  }

  @media (min-width: $md) {
    bottom: 55px;
    transform: translateX(-50%);
  }
}

.formWrapperText {
  display: flex;
  flex-direction: column;
  padding: 96px 51px 0 55px;

  .formLogo {
    position: relative;
    transform: none;
    bottom: auto;
    left: auto;
  }
}

.formList {
  padding-left: 35px;

  &__title {
    font-family: MontserratMedium, sans-serif;
    font-size: 14px;
    margin: 0 0 12px;
  }

  &__text {
    margin-bottom: 25px;

    &:nth-of-type(2) {
      margin-bottom: 15px;
    }
  }

  p {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    margin: 0 0 5px;
  }

  &__socials {
    display: flex;
    align-items: center;

    img {
      height: 15px;
      width: auto;
      vertical-align: middle;
      margin-right: 15px;
    }
  }
}

.formWrapperContact {
  background-color: transparent;
  justify-content: center;
  flex-direction: row;

  .formWrapperBlock {
    background: linear-gradient(
      135deg,
      $white-color 0%,
      $form-bg-1 5%,
      $gradient-bg 60%,
      $gradient-bg 100%
    );
    flex-direction: column;
  }

  .formWrapperText {
    padding: 40px 20px 0 20px;
    align-items: center;
    text-align: center;
  }

  .formLogo {
    margin-bottom: 38px;
  }

  .formList {
    padding-left: 0;

    &__socials {
      justify-content: center;
    }
  }
}

.formSeparator {
  background-color: $form-separator;
  height: 252px;
  width: 1px;
  margin-top: 152px;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

@media (min-width: $sm640) {
  .formWrapperContact {
    flex-direction: column;

    .formWrapperBlock {
      flex-direction: row;
    }

    .formSeparator {
      display: block;
    }

    .formWrapperText {
      align-items: initial;
      text-align: left;
    }

    .formList {
      padding-left: 25px;

      &__socials {
        justify-content: initial;
      }
    }
  }
}

@media (min-width: $md) {
  .formWrapperContact {
    .formWrapperText {
      padding: 96px 51px 0 55px;
    }

    .formList {
      padding-left: 35px;
    }
  }
}
