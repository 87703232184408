@import "../../../styles/colors";
@import "../../../styles/mixin";

.header {
  align-items: center;
  background: $campaign-color;
  border-radius: 5px;
  color: $white-color;
  position: relative;
  font-family: MontserratSemiBold, "sans-serif";
  font-size: 16px;
  display: flex;
  padding: 24px 85px 15px 32px;
  letter-spacing: 0;
  &.center {
    text-align: center;
    justify-content: center;
    padding: 15px 32px;
  }
  &.lightHeader {
    color: $text-color;
    padding-right: 20px;
    .subtitle {
      color: $text-color;
      
    }
  }
}

// screens smaller than 550px
@media (max-width: 550px) {
  .header {
    padding: 20px 0 20px 0px;
    justify-content: center;
  }
}
.titles {
  max-width: 82%;
}

.number {
  font-size: 45px;
  padding-right: 15px;
  text-align: center;
}

.checked {
  &:after {
    content: "";
    display: inline-block;
    background-image: url(../../../assets/images/icons/icon-check-green.svg);
    background-repeat: no-repeat;
    background-size: 18px 13px;
    height: 13px;
    width: 18px;
    margin-left: 15px;
    //position: absolute;
    //right: 0;
    //top: 0;
  }
}
.disabled {
  opacity: 0.8;
}
.subtitle {
  color: $white-color;
  font-family: Montserrat, "sans-serif";
  font-size: 12px;
  margin-top: 10px;
}
.iconContainer {
  height: auto;
  display: flex;
  padding-right: 15px;
  margin-left: auto;
  cursor: pointer;
}
.tipIcon {
  align-items: center;
  justify-content: center;
  //background: $white-color;
  border-radius: 50%;
  border: 3px solid $help-grey;
  display: flex;
  position: relative;
  height: 30px;
  width: 30px;
  margin: auto;
  &:after {
    content: "";
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
    @include maskIcon(
      "../assets/images/icons/icon-question-mark.svg",
      $help-grey
    );
  }
}

.twoCols {
  &__title {
    display: flex;
    align-items: center;

    .iconContainer {
      margin: 0;
      padding-left: 10px;

      .tipIcon {
        border: 1px solid $help-grey;
        height: 20px;
        width: 20px;
        &:after {
          background-size: 5px 5px;
          height: 9px;
          width: 9px;
        }
      }
    }
  }

  .titles {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 1px;
      width: 295px;
      background-color: $text-color-4;
      bottom: -15px;
      left: 0;
    }
  }
}
